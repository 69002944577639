import React from "react";

// Root Include
//const Root = React.lazy(() => import("./pages/Home/indexRoot"));

//Main Index
//const Main = React.lazy(() => import("./pages/Insurance/index"));


//Utility
const PagePrivacy = React.lazy(() =>
  import("./pages/Pages/Utility/PagePrivacy")
);
const PageTerms = React.lazy(() => import("./pages/Pages/Utility/PageTerms"));

const Insurance = React.lazy(() => import("./pages/Insurance/index"));
const CargoCoverage = React.lazy(() => import("./pages/Pages/Utility/CargoCoverage"));
const CommercialAutoInsurance = React.lazy(() => import("./pages/Pages/Utility/CommercialAutoInsurance"));
const CommercialPropertyInsurance = React.lazy(() => import("./pages/Pages/Utility/CommercialPropertyInsurance"));
const CropCoverage = React.lazy(() => import("./pages/Pages/Utility/CropCoverage"));
const GeneralLiability = React.lazy(() => import("./pages/Pages/Utility/GeneralLiability"));
const ProductLiability = React.lazy(() => import("./pages/Pages/Utility/ProductLiability"));
const WorkersCompensation = React.lazy(() => import("./pages/Pages/Utility/WorkersCompensation"));
const CannabisHempCBD = React.lazy(() => import("./pages/Pages/Utility/CannabisHempCBD"));
const VapeEcig = React.lazy(() => import("./pages/Pages/Utility/VapeEcigsIndustry"));

const PageError = React.lazy(() => import("./pages/Pages/Special/PageError"));
const PageAboutUs = React.lazy(() => import("./pages/Pages/PageAboutUs"));
//Contact
const Quote = React.lazy(() =>
  import("./pages/Pages/Contact/Quote")
);
const PageContactOne = React.lazy(() =>
  import("./pages/Pages/Contact/PageContactOne")
);

//Shop
const ShopProducts = React.lazy(() =>
  import("./pages/Pages/Shop/ShopProducts")
);
const ShopProductsLists = React.lazy(() =>
  import("./pages/Pages/Shop/ShopProductList")
);
const ShopProductDetail = React.lazy(() =>
  import("./pages/Pages/Shop/ShopProductDetail")
);
const ShopCart = React.lazy(() => import("./pages/Pages/Shop/ShopCart"));
const ShopCheckouts = React.lazy(() =>
  import("./pages/Pages/Shop/ShopCheckouts")
);
const ShopMyAccount = React.lazy(() =>
  import("./pages/Pages/Shop/ShopMyAccount")
);

const routes = [
  //routes without Layout

  //Quote without layout
  {
    path: "/get-a-quote",
    component: Quote,
    isWithoutLayout: false,
  },



  //Special Pages

  { path: "/page-error", component: PageError, isWithoutLayout: true },


  // Landings
  { path: "/index-event", component: Event },
  { path: "/cargo-coverage", component: CargoCoverage },
  { path: "/commercial-auto-insurance", component: CommercialAutoInsurance },
  { path: "/commercial-property-insurance", component: CommercialPropertyInsurance },
  { path: "/crop-coverage", component: CropCoverage },
  { path: "/general-liability", component: GeneralLiability },
  { path: "/product-liability", component: ProductLiability },
  { path: "/workers-compensation", component: WorkersCompensation },
  { path: "/contact-us", component: PageContactOne },
  { path: "/Cannabis-Hemp-CBD", component: CannabisHempCBD},
  { path: "/vape-e-cig", component: VapeEcig},


  { path: "/page-aboutus", component: PageAboutUs },



  //Shop
  { path: "/shop-grids", component: ShopProducts },
  { path: "/shop-lists", component: ShopProductsLists },
  { path: "/shop-product-detail", component: ShopProductDetail },
  { path: "/shop-cart", component: ShopCart },
  { path: "/shop-checkouts", component: ShopCheckouts },
  { path: "/shop-myaccount", component: ShopMyAccount },






  //Page Contact
  { path: "/page-contact-one", component: PageContactOne },


  //Utility
  { path: "/page-terms", component: PageTerms },
  { path: "/page-privacy", component: PagePrivacy },


  //Index Main
  { path: "/index", component: Insurance },

  //Index root

  //{ path: "/", component: Root, isWithoutLayout: true, exact: true },
  { path: "/", component: Insurance },
  { component: PageError, isWithoutLayout: true, exact: false },
];

export default routes;
